"use client";

import { renderReactScope as _renderReactScope$ } from "million/react-server";
import { block as _block$ } from "million/react-server";
import Error from "next/error";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
const _GlobalError2 = ({
  error
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  const _$ = _renderReactScope$(<Error />, false);
  return <_puppet$ _$={_$} __props={{
    error: error
  }} />;
};
const _GlobalError = ({
  error
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html>
      <body>
        <Error />
      </body>
    </html>;
};
const _puppet$ = /*million:transform*/_block$(({
  _$
}) => {
  return <html>
      <body>
        {_$}
      </body>
    </html>;
}, {
  svg: false,
  original: _GlobalError,
  shouldUpdate: (_, $) => _?._$ !== $?._$
});
const GlobalError = _GlobalError2;
export default GlobalError;